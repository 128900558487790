import React from "react"
import { Row, Container } from "react-bootstrap"

import InnerWrap from "../components/_Wrap/inner-wrap"
import Hero from "../components/Hero"

import WithPicture from "../components/Paragraph/WithPicture"
import SEO from "../components/seo"
import CTA from "../components/CTA"

import { graphql } from "gatsby"
import { makeLink } from "../utils/google-pic-format"

const GlassRetail = ({ data }) => {
  const { content, header } = data
  return (
    <React.Fragment>
      <SEO
        title="Glass Retail & Gift Shop"
        keywords={[`brazee`, `cincinnati`, `glass retail`, `gift shop`]}
      />
      <Hero src={makeLink(header.edges[0].node.headerPicture)} />

      <Container fluid>
        <Row>
          <InnerWrap>
            <WithPicture
              src={makeLink(content.edges[0].node.image)}
              title={header.edges[0].node.sitetitle}
              subheading=""
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: content.edges[0].node.content.replace(/\n/g, "<br/>"),
                }}
              />
            </WithPicture>
          </InnerWrap>
        </Row>
        <Row>
          <CTA />
        </Row>
      </Container>
      <div style={{ height: "50px", backgroundColor: "white" }}></div>
    </React.Fragment>
  )
}
export const glassRetailQuery = graphql`
  query retailQuery {
    header: allGoogleSheetGlassRetailGiftShopRow {
      edges {
        node {
          headerPicture
          sitetitle
        }
      }
    }
    content: allGoogleSheetGlassRetailGiftShopRow {
      edges {
        node {
          content
          image
          title
        }
      }
    }
  }
`
export default GlassRetail
